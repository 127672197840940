import React, { useContext } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';

import StoreContext from './../../context/StoreContext';
import {
  // Grid,
  Product,
  Title,
  PriceTag,
} from './styles';
import { Img } from './../../utils/styles';
import styled from '@emotion/styled';
import { Theme } from '@sashka/theme';

export const Grid = styled('section')<{ theme: Theme }>(({ theme }) => {
  return {
    display: 'grid',
    gap: '2.5rem',
    gridTemplateColumns: 'repeat(1, 1fr)',
    [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
  };
});

const ProductGrid = () => {
  const {
    store: { checkout },
  } = useContext(StoreContext);
  const { allShopifyProduct } = useStaticQuery(
    graphql`
      query {
        allShopifyProduct(sort: { fields: [createdAt], order: DESC }) {
          edges {
            node {
              id
              title
              handle
              createdAt
              images {
                id
                originalSrc
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 910) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
              }
              variants {
                price
              }
            }
          }
        }
      }
    `
  );

  const getPrice = price => {
    return Intl.NumberFormat(undefined, {
      currency: checkout.currencyCode ? checkout.currencyCode : 'EUR',
      minimumFractionDigits: 2,
      style: 'currency',
    }).format(parseFloat(price ? price : 0));
  };

  return (
    <Grid>
      {allShopifyProduct.edges ? (
        allShopifyProduct.edges.map(
          ({
            node: {
              id,
              handle,
              title,
              images: [firstImage],
              variants: [firstVariant],
            },
          }) => (
            <Product key={id}>
              <Link to={`/product/${handle}/`}>
                {firstImage && firstImage.localFile && (
                  <Img fluid={firstImage.localFile.childImageSharp.fluid} alt={handle} />
                )}
              </Link>
              <Title>{title}</Title>
              <PriceTag>{getPrice(firstVariant.price)}</PriceTag>
            </Product>
          )
        )
      ) : (
        <p>No Products found!</p>
      )}
    </Grid>
  );
};

export default ProductGrid;
