import React from 'react';
import { Link } from 'gatsby';

import SEO from '~/components/seo';
import ProductGrid from '../components/ProductGrid/ProductGrid';
import HeroSection from '../components/HeroSection/HeroSection';

const IndexPage = () => (
  <>
    <SEO title="Home" keywords={[`thesashka`, `sashka`, 'shopify', 'ecommerce']} />
    <HeroSection
      {...{
        title: 'Awesome Demo Store',
        imgSrc: 'https://i.picsum.photos/id/879/3840/2160.jpg?hmac=Lk3RPTLfVJZu5fu4eoSDbmI8-8yTmDFlakJN-XVYzEw',
      }}
    />
    <ProductGrid />
    <Link to="/page-2/">Go to page 2</Link>
  </>
);

export default IndexPage;
