import * as React from 'react';
import styled from '@emotion/styled';
import { Theme } from '@sashka/theme';
import { Typography } from '@sashka/ui-lib';

export interface HeroSectionProps {
  title: string;
  subtitle?: string;
  imgSrc?: string;
  cta?: React.ReactNode;
}

const HeroSectionWrapper = styled('div')<{ theme: Theme; imgSrc?: string }>(({ theme, imgSrc }) => ({
  height: '100vh',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  ...(imgSrc && {
    backgroundImage: `url('${imgSrc}')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  }),
}));

const ContentWrapper = styled('div')<{ theme: Theme }>(({ theme }) => ({
  padding: theme.gutters.base * 2,
  backgroundColor: 'inherit',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const HeroSection: React.FunctionComponent<HeroSectionProps> = ({ title, subtitle, cta, imgSrc }) => {
  return (
    <HeroSectionWrapper
      {...{
        imgSrc,
      }}
    >
      <ContentWrapper>
        <Typography
          {...{
            variant: 'h1',
            component: 'h1',
            color: 'contrast',
          }}
        >
          {title}
        </Typography>

        {subtitle && (
          <Typography
            {...{
              variant: 'h2',
              component: 'h2',
            }}
          >
            {subtitle}
          </Typography>
        )}

        {cta}
      </ContentWrapper>
    </HeroSectionWrapper>
  );
};

export default HeroSection;
